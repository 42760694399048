const firebaseConfig = {
    apiKey: "AIzaSyAE8P7i9VC_5zSjXy0WOhByzPAjlS9ZfbY",
    authDomain: "su-app-f8a07.firebaseapp.com",
    databaseURL: "https://su-app-f8a07.firebaseio.com",
    projectId: "su-app-f8a07",
    storageBucket: "su-app-f8a07.appspot.com",
    messagingSenderId: "36861546601",
    appId: "1:36861546601:web:1654234b73df677dcd12f8"
};

export default firebaseConfig;
